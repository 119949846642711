import {ApolloError} from '@apollo/client'

import {
  useCassandraQuery,
  UseCassandraQuerySelectedDataType,
} from '@possible/cassandra/src/utils/hooks'
import {CreateCassandraSelector} from '@possible/cassandra/src/utils/selectors'
import {orderBy, get} from 'lodash'

import {AccountListDocument, AccountListQuery} from 'src/lib/bank/queries/AccountList.gqls'
import {logAddPaymentMethodErrorAndShowException} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'

const LinkedAccountsSelector = CreateCassandraSelector(AccountListDocument, (data) => {
  type LinkedAccount = NonNullable<(typeof data)['me']['bankAccounts']['all']>[number]
  let preferredAccount: LinkedAccount | undefined = undefined
  let latestAccount: LinkedAccount | undefined = undefined
  if (data?.me.bankAccounts.all) {
    for (let i = 0; i < data?.me.bankAccounts.all?.length; i++) {
      const thisAcct = data.me.bankAccounts.all[i]
      if (thisAcct.preferredFundingSource === true) {
        // the type definition of LinkedAccount says that preferredFundingSource may be false
        // but at this point we KNOW it's true. force it to true so that typescript knows it
        // can't be false for preferredAccount, it's not able to infer that preferredFundingSource
        // will be true even tho we check with .preferredFundingSource === true
        preferredAccount = {
          ...thisAcct,
          preferredFundingSource: true,
        }
      }
    }

    latestAccount = orderBy(
      data.me.bankAccounts.all,
      [(o): string | null => get(o, 'lastSyncedAt', '0')],
      ['desc'],
    )[0]
  }

  return {
    linkedAccounts: data?.me?.bankAccounts?.all ?? undefined,
    preferredAccount: preferredAccount,
    latestAccount: latestAccount,
  }
})

export type UseGetLinkedAccountsResult = {
  linkedAccountsData: UseCassandraQuerySelectedDataType<typeof LinkedAccountsSelector> | undefined
  linkedAccountsError: ApolloError | undefined
  linkedAccountsRefetch: ReturnType<
    typeof useCassandraQuery<
      AccountListQuery,
      object,
      UseCassandraQuerySelectedDataType<typeof LinkedAccountsSelector>
    >
  >['refetch']
  isLoadingLinkedAccounts: boolean
}

/**
 * Hook to get a user's linked accounts and their primary account. Note that you may be looking for
 * PaymentMethods instead of LinkedAccounts since payment methods added without Plaid/Yodlee will
 * not be included in the LinkedAccounts list.
 */
export const useGetLinkedAccounts = (): UseGetLinkedAccountsResult => {
  const {
    selectedData: linkedAccountsData,
    loading: isLoadingLinkedAccounts,
    error: linkedAccountsError,
    refetch: linkedAccountsRefetch,
  } = useCassandraQuery(
    AccountListDocument,
    {
      fetchPolicy: 'cache-first',
      onError: (error) => {
        logAddPaymentMethodErrorAndShowException(error, 'useGetLinkedAccounts failed')
      },
    },
    LinkedAccountsSelector,
  )

  return {linkedAccountsData, linkedAccountsError, isLoadingLinkedAccounts, linkedAccountsRefetch}
}
