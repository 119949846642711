import React from 'react'
import {Platform, TextInput, TextInputProps, StyleSheet} from 'react-native'
import {debounce} from 'lodash'
import {DeepNonNullable} from 'utility-types'

import {DefaultVariantsColor, NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {littleGap} from 'src/designSystem/layout'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {VariantsStyle} from 'src/designSystem/typography'

const debounceErrorTime = 1000

type LoanAmountInputProps = Pick<
  DeepNonNullable<TextInputProps>,
  'onChangeText' | 'onSubmitEditing'
> & {
  loanAmount: string
  invalidAmountText: string
  isValidAmount: boolean
}

const LoanAmountInput: React.FC<LoanAmountInputProps> = (props) => {
  const {
    loanAmount,
    isValidAmount,
    invalidAmountText,
    onChangeText,
    onSubmitEditing: handleOnSubmitEditing,
  } = props

  const [isValidAmountDebounced, setIsValidAmountDebounced] = React.useState<boolean>(isValidAmount)

  const debouncedSetIsValidAmount = React.useMemo(
    () =>
      debounce((value: boolean) => {
        setIsValidAmountDebounced(value)
      }, debounceErrorTime),
    [],
  )

  React.useEffect(() => {
    if (isValidAmount) {
      // Immediately set valid amount when valid
      setIsValidAmountDebounced(isValidAmount)
    }
    debouncedSetIsValidAmount(isValidAmount)
  }, [isValidAmount, debouncedSetIsValidAmount])

  function handleOnChangeText(value: string): void {
    const valueWithoutDollarSign = value.replace('$', '')

    if (!valueWithoutDollarSign) {
      onChangeText('')
      return
    }

    // do not set if value contains non-numeric characters
    if (!/^\d+$/.test(valueWithoutDollarSign)) {
      return
    }

    // Remove leading zeros, ensure input is an integer
    const parsedValue = parseInt(valueWithoutDollarSign)
    if (isNaN(parsedValue)) {
      return
    }

    const newLoanAmount = parsedValue.toString()

    onChangeText(newLoanAmount)
  }

  return (
    <Box>
      <TextInput
        cursorColor={DefaultVariantsColor['textPrimary']}
        style={[styles.amountInput, !isValidAmountDebounced && styles.amountInputInvalid]}
        value={`$${loanAmount}`}
        onChangeText={handleOnChangeText}
        onSubmitEditing={handleOnSubmitEditing}
        maxLength={4}
        inputMode="numeric"
        testID="LoanAmountInput-TextInput"
        enterKeyHint="done"
      />
      {isValidAmountDebounced ? null : (
        <Box padding={littleGap} direction="row" align="center" gap="little">
          <SvgIcon name="error" colorVariant="error" isFilled={true} />
          <PFText color="error" variant="p_sm">
            {invalidAmountText}
          </PFText>
        </Box>
      )}
    </Box>
  )
}

export {LoanAmountInput}
export type {LoanAmountInputProps}

const styles = StyleSheet.create({
  amountInputInvalid: {
    borderColor: NamedColors.VERMILLION,
    borderWidth: 2,
  },
  amountInput: {
    ...VariantsStyle.d2(DefaultVariantsColor['textPrimary']),
    height: 100,
    width: '100%',
    backgroundColor: NamedColors.WHITESMOKE,
    borderRadius: 10,

    padding: 8,
    textAlign: 'center',
    fontSize: 72,

    ...Platform.select({
      web: {
        outlineStyle: 'none',
      },
    }),
  },
})
