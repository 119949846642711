import React, {FC} from 'react'
import {useNavigation} from '@react-navigation/native'
import {useTranslation} from 'react-i18next'
import {StackNavigationProp} from '@react-navigation/stack'

import Box from 'src/designSystem/components/atoms/Box/Box'
import Alert, {AlertProps} from 'src/designSystem/components/molecules/Alert/Alert'

import {CardAccountDashboardStatus, CardAccountPOTStatus} from 'src/products/card/types'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {useIsBalancePaidOff} from 'src/products/card/hooks/useIsBalancePaidOff'
import {useIsFeatureFlagEnabled} from 'src/lib/experimentation/useIsFeatureFlagEnabled'
import {
  isOver150DaysDelinquent,
  isOver30DaysDelinquent,
  isOver60DaysDelinquent,
  isOver90DaysDelinquent,
} from 'src/products/card/Dashboard/util'
import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {CardMinPaymentsDocument} from 'src/designSystem/components/molecules/UpcomingPaymentsCard/CardMinPayments.gqls'

type CardAlertProps = {
  accountStatus?: CardAccountDashboardStatus
  accountPOTStatus: CardAccountPOTStatus
  delinquentNumberOfDays: number | undefined | null
}

const CardAlert: FC<CardAlertProps> = ({
  accountStatus,
  accountPOTStatus,
  delinquentNumberOfDays,
}) => {
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>()
  const {t} = useTranslation('CardAlert')
  const isBalancePaidOff = useIsBalancePaidOff()
  const cardCreditReportingFeatureFlag = useIsFeatureFlagEnabled('card-credit-reporting-ui-updates')
  const {selectedData: cardMinPayResponse} = useCassandraQuery(
    CardMinPaymentsDocument,
    {
      fetchPolicy: 'cache-first',
    },
    (data) => data.me.cardAccounts.active,
  )
  const navigateToHowDelinquencyAffectsMe = (): void =>
    navigation.navigate('CardHowDelinquencyAffectMe')
  const navigateToAdHocPayment = (): void =>
    navigation.navigate('CardAdhocPayment', {screen: 'CardAdhocPaymentAmountEntry'})
  const getOverdueAlertProps = (): {level: AlertProps['level']; title: string} => {
    return {level: 'warning', title: t('YourPaymentIsOverdue')}
  }

  const getOverdueNSFAlertProps = (): {
    level: AlertProps['level'] | undefined
    title: string
    description: string
  } => {
    return {level: 'warning', title: t('YourPaymentIsOverdue'), description: t('CardNowLocked')}
  }

  const getAtRiskDelinquencyAlertProps = (): {
    level: AlertProps['level'] | undefined
    title: string
    onPress: () => void
  } => {
    return {level: 'warning', title: t('AccountAtRisk'), onPress: navigateToHowDelinquencyAffectsMe}
  }

  const getAtRiskDelinquencyNSFAlertProps = (): {
    level: AlertProps['level'] | undefined
    title: string
    description: string
    onPress: () => void
  } => {
    return {
      level: 'warning',
      title: t('AccountAtRisk'),
      description: t('CardNowLocked'),
      onPress: navigateToHowDelinquencyAffectsMe,
    }
  }

  const getDelinquentAlertProps = (): {
    level: AlertProps['level'] | undefined
    title: string
    onPress: () => void
  } => {
    return {
      level: 'error',
      title: t('AccountNowDelinquent'),
      onPress: navigateToHowDelinquencyAffectsMe,
    }
  }

  const getDelinquencyReportedAlertProps = (): {
    level: AlertProps['level'] | undefined
    title: string
    onPress: () => void
  } => {
    if (cardMinPayResponse?.supportsMinPay && isOver30DaysDelinquent(delinquentNumberOfDays)) {
      return {
        level: 'error',
        title: t('OverThirtyDays'),
        onPress: navigateToAdHocPayment,
      }
    }
    if (cardCreditReportingFeatureFlag) {
      if (isOver30DaysDelinquent(delinquentNumberOfDays)) {
        return {
          level: 'error',
          title: t('OverThirtyDays'),
          onPress: navigateToAdHocPayment,
        }
      } else if (isOver60DaysDelinquent(delinquentNumberOfDays)) {
        return {
          level: 'error',
          title: t('OverSixtyDays'),
          onPress: navigateToAdHocPayment,
        }
      } else if (
        isOver90DaysDelinquent(delinquentNumberOfDays) ||
        isOver150DaysDelinquent(delinquentNumberOfDays)
      ) {
        return {
          level: 'error',
          title: t('OverNinetyDays'),
          onPress: navigateToAdHocPayment,
        }
      }
    }

    return {
      level: 'error',
      title: t('DelinquentPay'),
      onPress: navigateToHowDelinquencyAffectsMe,
    }
  }

  const getSuspendedPayOverTimeAlertProps = (): {
    level: AlertProps['level'] | undefined
    title: string
    description?: string
  } => {
    if (accountPOTStatus === CardAccountPOTStatus.OverdueOneMissedPayment) {
      return {level: 'warning', title: t('OneOverduePayments')}
    } else if (accountPOTStatus === CardAccountPOTStatus.OverdueMultipleMissedPayments) {
      return {level: 'error', title: t('MultipleOverduePayments')}
    } else {
      return {level: undefined, title: '', description: ''}
    }
  }

  const getDeactivatedAlertProps = (): {
    level: AlertProps['level'] | undefined
    title: string
    description?: string
    onPress?: () => void
  } => {
    if (isBalancePaidOff) {
      return {level: undefined, title: '', description: ''}
    } else if (
      cardMinPayResponse?.supportsMinPay &&
      accountStatus === CardAccountDashboardStatus.DeactivatedDelinquent
    ) {
      return {
        level: 'error',
        title: t('AccountNowDelinquent'),
        onPress: navigateToHowDelinquencyAffectsMe,
      }
    } else if (cardMinPayResponse?.supportsMinPay) {
      return {level: undefined, title: '', description: ''}
    } else {
      return {level: 'warning', title: t('PayOffYourBalance'), onPress: navigateToAdHocPayment}
    }
  }

  const getChargedOffAlertProps = (): {
    level: AlertProps['level'] | undefined
    title: string
    description?: string
    onPress?: () => void
  } => {
    return {
      level: 'error',
      title: t('ChargedOff'),
      onPress: navigateToAdHocPayment,
    }
  }

  const getAlertProps: () => {
    level: AlertProps['level'] | undefined
    description?: string
    title: string
    onPress?: () => void
  } = () => {
    switch (accountStatus) {
      case CardAccountDashboardStatus.Overdue:
        return getOverdueAlertProps()
      case CardAccountDashboardStatus.OverdueNSF:
        return getOverdueNSFAlertProps()
      case CardAccountDashboardStatus.AtRiskDelinquency:
        return getAtRiskDelinquencyAlertProps()
      case CardAccountDashboardStatus.AtRiskDelinquencyNSF:
        return getAtRiskDelinquencyNSFAlertProps()
      case CardAccountDashboardStatus.Delinquent:
      case CardAccountDashboardStatus.DelinquentNSF:
        return getDelinquentAlertProps()
      case CardAccountDashboardStatus.DelinquencyReported:
      case CardAccountDashboardStatus.DelinquencyReportedNSF:
      case CardAccountDashboardStatus.DeactivatedDelinquentThirtyDays:
        return getDelinquencyReportedAlertProps()
      case CardAccountDashboardStatus.SuspendedPayOverTime:
        return getSuspendedPayOverTimeAlertProps()
      case CardAccountDashboardStatus.Deactivated:
        return getDeactivatedAlertProps()
      case CardAccountDashboardStatus.DeactivatedDelinquent:
        return getDelinquentAlertProps()
      case CardAccountDashboardStatus.ChargedOff:
        return getChargedOffAlertProps()
      default:
        return {level: undefined, title: '', description: ''}
    }
  }

  const {level, title, description, onPress: handleOnPress} = getAlertProps()
  return (
    <Box paddingHorizontal={'medium'} paddingBottom="small" testID="Alert-Id">
      {level && accountStatus !== CardAccountDashboardStatus.Active ? (
        <Alert
          level={level}
          title={title}
          description={description ?? ''}
          onPress={handleOnPress}
        />
      ) : null}
    </Box>
  )
}

export default CardAlert
