import {PartialState, StackNavigationState} from '@react-navigation/native'

import {CardAccountStatuses} from '@possible/cassandra/src/types/types.mobile.generated'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {ProductHubProductsQuery} from 'src/products/general/ProductHub/ProductHub.gqls'
import {canViewCardDashboard} from 'src/products/general/ProductHub/ProductHub.utils'
import {useIsInCardsOnboarding} from 'src/products/general/ProductHub/UseIsInCardsOnboarding/useIsInCardsOnboarding'
import {
  ProductsQueryAllEligibleOffersType,
  ProductsQueryApplicationsType,
  SelectedOfferInformation,
} from 'src/workflows/types'

/**
 * The Product Hub currently does 2 things:
 * Depending on the user's state the Product Hub may either redirect the user to a new screen,
 * or display a set of tiles
 * This hook is responsible for determining if the user should be redirected.
 * @returns the navigation route if the user should be redirected,
 * or undefined if the user should not be redirected.
 */

export type UseProductHubRedirectReturn = {
  isLoading: boolean
  redirectRoute?: PartialState<StackNavigationState<MainStackParamList>>
}

export type UseProductHubRedirect = (params: {
  selectedOffer: SelectedOfferInformation | null
  latestCardAccountStatus?: Partial<CardAccountStatuses>
  latestLoanAggregateStatus?: NonNullable<
    ProductHubProductsQuery['me']['loans']['latestActionableLoan']
  >['aggregateStatus']
  isAccountStatusLoading: boolean
  allApplications: ProductsQueryApplicationsType[]
  allOffers: ProductsQueryAllEligibleOffersType
  isSelectDefaultOfferLoading: boolean
}) => UseProductHubRedirectReturn

export const useProductHubRedirect: UseProductHubRedirect = ({
  selectedOffer,
  latestCardAccountStatus,
  latestLoanAggregateStatus,
  isAccountStatusLoading,
  allApplications,
  allOffers,
  isSelectDefaultOfferLoading,
}) => {
  const {isInCardsOnboarding, isLoading: isInOnboardingLoading} = useIsInCardsOnboarding()

  if (isAccountStatusLoading || isInOnboardingLoading || isSelectDefaultOfferLoading) {
    return {
      isLoading: true,
    }
  }

  // If the user has completed the Signup flow, and has 1 offer with unmetPreReqs, direct them to OfferApplicationWorkflow
  if (selectedOffer && selectedOffer.unmetPreReqs.length > 0) {
    if (allApplications.length === 0) {
      // if we have no previous applications (initial product onboarding)
      // we should send the user to the offer application workflow with no
      // previous screen
      // this will signal workflows to replace the left header button
      // with `Logout`
      return {
        isLoading: false,
        redirectRoute: {
          index: 0,
          routes: [
            {
              name: 'OfferApplicationWorkflow',
              params: {offerId: selectedOffer.offerId},
            },
          ],
        },
      }
    } else {
      // if we have previous applications, we should send the user to the offer
      // application workflow with the previous screen being the dashboard
      // this will signal workflows to NOT replace the left header button at all
      return {
        isLoading: false,
        redirectRoute: {
          index: 1,
          routes: [
            {
              name: 'Dashboard',
            },
            {
              name: 'OfferApplicationWorkflow',
              params: {offerId: selectedOffer.offerId},
            },
          ],
        },
      }
    }
  }

  // If user has 1 active card application, redirect to the Cards dashboard
  if (canViewCardDashboard(latestCardAccountStatus)) {
    return {
      isLoading: false,
      redirectRoute: {
        index: 0,
        routes: [{name: 'CardDashboard'}],
      },
    }
  }
  // loan user has an active loan, send them to dashboard
  if (latestLoanAggregateStatus?.__typename === 'ActiveLoanAggregateStatus') {
    return {
      isLoading: false,
      redirectRoute: {
        index: 0,
        routes: [{name: 'Dashboard'}],
      },
    }
  }

  // If user has a loan application and is not in card onboarding, redirect
  if (latestLoanAggregateStatus?.__typename && !isInCardsOnboarding) {
    // workflow user should be sent to the loan dashboard
    return {
      isLoading: false,
      redirectRoute: {
        index: 0,
        routes: [{name: 'Dashboard'}],
      },
    }
  }

  // If user has no offers and no applications redirect to the waitlist screen
  if (allOffers.length === 0 && allApplications.length === 0) {
    return {
      isLoading: false,
      redirectRoute: {
        index: 0,
        routes: [{name: 'UnsupportedStateWaitList'}],
      },
    }
  }

  return {
    isLoading: false,
  }
}
