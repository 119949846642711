import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {NamedColors} from 'src/designSystem/colors'
import {BottomSheet} from 'src/designSystem/components/atoms/BottomSheet/BottomSheet'
import Box from 'src/designSystem/components/atoms/Box/Box'
import Button from 'src/designSystem/components/atoms/Button/Button'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {smallGap} from 'src/designSystem/layout'
import {EstimatedTotal} from 'src/products/loans/LoanAmountSelectionV2/EstimatedTotal'
import {
  LoanAmountInput,
  LoanAmountInputProps,
} from 'src/products/loans/LoanAmountSelectionV2/LoanAmountInput'

type LoanAmountSelectionV2TemplateProps = {
  prequalifiedAmount?: string

  maxLoanAmount: string
  minLoanAmount: string
  estimatedTotal: string
  installmentAmount: string
  totalInterestAndFees: string
  APRPercent: string
  buttonAmounts: Array<string>
  onLoanAmountChanged: LoanAmountInputProps['onChangeText']
  isLoadingOnContinue: boolean
  isLoadingEstimatedCosts: boolean
  onContinue: (amount: string) => Promise<void>
} & Pick<LoanAmountInputProps, 'isValidAmount' | 'loanAmount'>

export const LoanAmountSelectionV2Template = (
  props: LoanAmountSelectionV2TemplateProps,
): JSX.Element => {
  const {
    maxLoanAmount,
    minLoanAmount,
    estimatedTotal,
    installmentAmount,
    totalInterestAndFees,
    APRPercent,
    buttonAmounts,
    prequalifiedAmount,

    loanAmount,
    onLoanAmountChanged: handleLoanAmountChanged,
    isValidAmount,

    isLoadingOnContinue,
    isLoadingEstimatedCosts,
    onContinue,
  } = props

  const [showPrequalifiedInfo, setShowPrequalifiedInfo] = React.useState<boolean>(false)

  const {t} = useTranslation('LoanAmountSelectionV2')
  const isDisabled = !isValidAmount || isLoadingOnContinue
  const handleOnContinue = (): void => void onContinue(loanAmount)

  const handleOnSubmitEditing = (): void => {
    if (isDisabled) return

    handleOnContinue()
  }

  return (
    <Page
      variant="generic"
      smallTopGap={true}
      title={t('ChooseAnAmount')}
      buttonProps={{
        type: 'singleButton',
        primary: {
          disabled: isDisabled,
          text: t('Continue'),
          loading: isLoadingOnContinue,
          onPress: handleOnContinue,
        },
      }}
    >
      {prequalifiedAmount ? (
        <Box>
          <PFText variant="p_semibold" textAlign="center" color={NamedColors.SILVER}>
            <Trans
              t={t}
              i18nKey="YourePrequalifiedFor"
              components={{
                Link: (
                  <SvgLink
                    textVariant="p_semibold"
                    linkType="inline"
                    linkText={`For $${prequalifiedAmount}`}
                    linkIcon="info"
                    onPress={(): void => setShowPrequalifiedInfo(true)}
                  />
                ),
              }}
            />
          </PFText>
          <PFText variant="p_sm" textAlign="center" color={NamedColors.SILVER}>
            {t('OrEnterAmount', {maxLoanAmount, minLoanAmount})}
          </PFText>
        </Box>
      ) : (
        <PFText variant="p_semibold" textAlign="center" color={NamedColors.SILVER}>
          {t('EnterAmount', {maxLoanAmount, minLoanAmount})}
        </PFText>
      )}

      <Box marginTop={smallGap}>
        <LoanAmountInput
          loanAmount={loanAmount}
          onChangeText={handleLoanAmountChanged}
          onSubmitEditing={handleOnSubmitEditing}
          isValidAmount={isValidAmount}
          invalidAmountText={t('InvalidAmount', {minLoanAmount, maxLoanAmount})}
        />
      </Box>

      <Box marginTop={smallGap}>
        <EstimatedTotal
          estimatedTotal={estimatedTotal}
          installmentAmount={installmentAmount}
          totalInterestAndFees={totalInterestAndFees}
          APRPercent={APRPercent}
          isLoadingEstimatedCosts={isLoadingEstimatedCosts}
        />
      </Box>

      <Box direction={'row'} flex gap={'little'} paddingVertical={smallGap}>
        {buttonAmounts.map((value) => (
          <Box key={value} flex>
            <Button
              aria-selected={loanAmount === value}
              mode={loanAmount === value ? 'selectedTab' : 'secondary'}
              size="medium"
              width="auto"
              onPress={(): void => handleLoanAmountChanged(value)}
            >
              {`$${value}`}
            </Button>
          </Box>
        ))}
      </Box>

      <PFText variant="p_semibold" textAlign="center" color={NamedColors.SILVER}>
        {t('FinalAmountCalculated')}
      </PFText>

      <BottomSheet
        visible={showPrequalifiedInfo}
        title={t('WhatDoesPrequalMean')}
        titleAlign="left"
        titleVariant="h3"
        onDismiss={(): void => setShowPrequalifiedInfo(false)}
        showDots={false}
        showCloseButton
        dismissButton={{text: t('ChooseMyAmount')}}
        fullWidthTitle={false}
      >
        <PFText variant="p">{t('PrequalExplanation')}</PFText>
      </BottomSheet>
    </Page>
  )
}
