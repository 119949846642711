export const PrequalEvents = {
  prequal_offer_shown: 'prequal_offer_shown',
  prequal_offer_not_shown: 'prequal_offer_not_shown',
  prequal_loan_slider_experiment_on: 'prequal_loan_slider_experiment_on',
  prequal_bank_link_shown: 'prequal_bank_link_shown',
  prequal_interstitial_shown: 'prequal_interstitial_shown',
  prequal_interstitial_shown_with_skip: 'prequal_interstitial_shown_with_skip',
  prequal_interstitial_skipped: 'prequal_interstitial_skipped',
  prequal_interstitial_closed: 'prequal_interstitial_closed',
  prequal_loan_pending: 'prequal_loan_pending',
  prequal_loan_more_info_needed: 'prequal_loan_more_info_needed',
  prequal_loan_approved: 'prequal_loan_approved',
} as const

export const DeeplinkEvents = {
  deeplink_received: 'deeplink_received',
  deeplink_saved_to_storage: 'deeplink_saved_to_storage',
  deeplink_read_from_storage: 'deeplink_read_from_storage',
  deeplink_cleared: 'deeplink_cleared',
} as const

export const CardEvents = {
  card_waiting_list_dashboard_viewed: 'card_waiting_list_dashboard_viewed', //https://www.figma.com/file/4QN0lb8NxS0oI1SFMvldyu/All-app-screens-Q4-2021?node-id=4821%3A19564
  card_waiting_list_dashboard_registered: 'card_waiting_list_dashboard_registered', //https://www.figma.com/file/4QN0lb8NxS0oI1SFMvldyu/All-app-screens-Q4-2021?node-id=4821%3A19565
  card_waiting_list_dashboard_support: 'card_waiting_list_dashboard_support',
  out_of_state_wait_list_page_viewed: 'out_of_state_wait_list_page_viewed',
  out_of_state_wait_list_registered: 'out_of_state_wait_list_registered',
  card_dashboard_viewed: 'card_dashboard_viewed', //https://www.figma.com/file/4QN0lb8NxS0oI1SFMvldyu/All-app-screens-Q4-2021?node-id=4835%3A19608
  card_activate_physical_dashboard_tile_viewed: 'card_activate_physical_dashboard_tile_viewed', //https://www.figma.com/file/4QN0lb8NxS0oI1SFMvldyu/All-app-screens-Q4-2021?node-id=4835%3A19634
  card_activate_physical_dashboard_tile_activate_cta:
    'card_activate_physical_dashboard_tile_activate_cta', //https://www.figma.com/file/4QN0lb8NxS0oI1SFMvldyu/All-app-screens-Q4-2021?node-id=4835%3A19643
  card_activate_physical_dashboard_tile_skip_cta: 'card_activate_physical_dashboard_tile_skip_cta', //https://www.figma.com/file/4QN0lb8NxS0oI1SFMvldyu/All-app-screens-Q4-2021?node-id=4835%3A19648
  activate_dashboard_card_viewed: 'activate_dashboard_card_viewed',
  activate_dashboard_card_cta: 'activate_dashboard_card_cta',
  card_approved_dashboard_tile_viewed: 'card_approved_dashboard_tile_viewed', //https://www.figma.com/file/4QN0lb8NxS0oI1SFMvldyu/All-app-screens-Q4-2021?node-id=4835%3A19563
  card_approved_dashboard_tile_cta: 'card_approved_dashboard_tile_cta', //https://www.figma.com/file/4QN0lb8NxS0oI1SFMvldyu/All-app-screens-Q4-2021?node-id=4835%3A19554
  pending_dashboard_card_viewed: 'pending_dashboard_card_viewed',
  apply_dashboard_card_viewed: 'apply_dashboard_card_viewed', //https://www.figma.com/file/4QN0lb8NxS0oI1SFMvldyu/All-app-screens-Q4-2021?node-id=4821%3A19585
  apply_dashboard_card_cta: 'apply_dashboard_card_cta', //https://www.figma.com/file/4QN0lb8NxS0oI1SFMvldyu/All-app-screens-Q4-2021?node-id=4821%3A19590
  reapply_dashboard_card_viewed: 'reapply_dashboard_card_viewed',
  reapply_dashboard_card_cta: 'reapply_dashboard_card_cta',
  card_reject_dashboard_card_viewed: 'card_reject_dashboard_card_viewed',
  card_expired_dashboard_card_viewed: 'card_expired_dashboard_card_viewed',
  card_deactivated_dashboard_card_viewed: 'card_deactivated_dashboard_card_viewed',
  card_error_dashboard_card_viewed: 'card_error_dashboard_card_viewed',
  view_transaction_cta: 'view_transaction_cta', //https://www.figma.com/file/4QN0lb8NxS0oI1SFMvldyu/All-app-screens-Q4-2021?node-id=4835%3A19629
  show_virtual_card: 'show_virtual_card', //https://www.figma.com/file/4QN0lb8NxS0oI1SFMvldyu/All-app-screens-Q4-2021?node-id=4838%3A19724
  card_dashboard_pay_now_cta: 'card_dashboard_pay_now_cta', //https://www.figma.com/file/4QN0lb8NxS0oI1SFMvldyu/All-app-screens-Q4-2021?node-id=4835%3A19616
  card_dashboard_pay_over_time_cta: 'card_dashboard_pay_over_time_cta', //https://www.figma.com/file/4QN0lb8NxS0oI1SFMvldyu/All-app-screens-Q4-2021?node-id=4835%3A19624
  card_dashboard_enroll_autopay_tile_cta: 'card_dashboard_enroll_autopay_tile_cta',
  // Adhoc payment flow
  card_adhoc_payment_select_amount_page_viewed: 'card_adhoc_payment_select_amount_page_viewed',
  card_adhoc_payment_select_amount_page_cta: 'card_adhoc_payment_select_amount_page_cta',
  card_adhoc_payment_amount_selected: 'card_adhoc_payment_amount_selected',
  card_adhoc_payment_review_page_viewed: 'card_adhoc_payment_review_page_viewed',
  card_adhoc_payment_scheduled: 'card_adhoc_payment_scheduled',
  card_adhoc_payment_success_screen_viewed: 'card_adhoc_payment_success_screen_viewed',
  card_adhoc_payment_relink_account_clicked: 'card_adhoc_payment_relink_account_clicked',
  card_adhoc_payment_failure_modal: 'card_adhoc_payment_failure_modal',
  card_verify_bank_details_page_viewed: 'card_verify_bank_details_page_viewed',
  card_verify_bank_details_submit_cta: 'card_verify_bank_details_submit_cta',
  card_verify_bank_details_payment_account_select:
    'card_verify_bank_details_payment_account_select',
  card_verify_bank_details_accounted_added_modal_confirm_cta:
    'card_verify_bank_details_accounted_added_modal_confirm_cta',
  card_view_payment_method_page_viewed: 'card_view_payment_method_page_viewed',
  card_payment_method_link_cta: 'card_payment_method_link_cta',
  card_view_payment_method_page_save_payment_cta: 'card_view_payment_method_page_save_payment_cta',
  card_view_payment_method_page_add_bank_cta: 'card_view_payment_method_page_add_bank_cta',
  card_view_payment_method_page_bank_select_cta: 'card_view_payment_method_page_bank_select_cta',
  // Other
  card_add_debit_card_page_viewed: 'card_add_debit_card_page_viewed',
  card_add_debit_card_page_on_submit_cta: 'card_add_debit_card_page_on_submit_cta',
  enable_pay_over_time_action: 'enable_pay_over_time_action',
  card_how_pay_over_time_works_page_viewed: 'card_how_pay_over_time_works_page_viewed',
  card_how_pay_over_time_works_page_cta: 'card_how_pay_over_time_works_page_cta',
  card_over_time_page_viewed: 'card_over_time_page_viewed',
  card_over_time_page_accept_plan_cta: 'card_over_time_page_accept_plan_cta',
  card_pay_over_time_confirmation_page_viewed: 'card_pay_over_time_confirmation_page_viewed',
  card_pay_over_time_confirmation_page_confirm_cta:
    'card_pay_over_time_confirmation_page_confirm_cta',
  card_finish_virtual_activation_page_viewed: 'card_finish_virtual_activation_page_viewed',
  card_finish_virtual_activation_page_cta: 'card_finish_virtual_activation_page_cta',
  enable_auto_pay_action: 'enable_auto_pay_action',
  card_autopay_schedule_page_viewed: 'card_autopay_schedule_page_viewed',
  card_autopay_schedule_page_opt_out_cta: 'card_autopay_schedule_page_opt_out_cta',
  card_autopay_schedule_page_continue_cta: 'card_autopay_schedule_page_continue_cta',
  card_join_autopay_page_viewed: 'card_join_autopay_page_viewed',
  card_autopay_overview_page_cta: 'card_autopay_overview_page_cta',
  card_manual_pay_confirmation_popup_viewed: 'card_manual_pay_confirmation_popup_viewed',
  card_manual_pay_schedule_page_viewed: 'card_manual_pay_schedule_page_viewed',
  card_manual_pay_schedule_page_cta: 'card_manual_pay_schedule_page_cta',
  card_activated_page_viewed: 'card_activated_page_viewed',
  card_activated_page_cta: 'card_activated_page_cta',
  card_activating_page_viewed: 'card_activating_page_viewed',
  card_physical_card_activated_page_viewed: 'card_physical_card_activated_page_viewed',
  card_physical_card_activated_page_cta: 'card_physical_card_activated_page_cta',
  card_physical_card_failed_activation_page_viewed:
    'card_physical_card_failed_activation_page_viewed',
  card_physical_card_failed_activation_page_retry_cta:
    'card_physical_card_failed_activation_page_retry_cta',
  card_physical_card_failed_activation_page_later_cta:
    'card_physical_card_failed_activation_page_later_cta',
  card_selection_page_viewed: 'card_selection_page_viewed',
  accept_cardholder_agreement_viewed: 'accept_cardholder_agreement_viewed',
  apply_for_card_selected: 'apply_for_card_selected',
  card_application_overview_page_viewed: 'card_application_overview_page_viewed',
  card_selfie_id_info_page_viewed: 'card_selfie_id_info_page_viewed',
  card_application_submitted: 'card_application_submitted',
  card_application_processing_page_viewed: 'card_application_processing_page_viewed',
  card_approved_page_viewed: 'card_approved_page_viewed', // https://www.figma.com/file/Yqrjmw6yPfOAIGCDseK0Ug/New-Activation-Flow-Events?node-id=0%3A1
  card_approved_page_cta: 'card_approved_page_cta', // https://www.figma.com/file/Yqrjmw6yPfOAIGCDseK0Ug/New-Activation-Flow-Events?node-id=0%3A1
  card_physical_activation_cvc_ssn_page_viewed: 'card_physical_activation_cvc_ssn_page_viewed', // https://www.figma.com/file/Yqrjmw6yPfOAIGCDseK0Ug/New-Activation-Flow-Events
  card_physical_activation_cvc_ssn_page_cta: 'card_physical_activation_cvc_ssn_page_cta', // https://www.figma.com/file/Yqrjmw6yPfOAIGCDseK0Ug/New-Activation-Flow-Events
  card_how_manual_pay_works_page_viewed: 'card_how_manual_pay_works_page_viewed',
  card_how_manual_pay_works_page_cta: 'card_how_manual_pay_works_page_cta',
  card_delinquency_explained_page_viewed: 'card_delinquency_explained_page_viewed',
  card_rejected_page_viewed: 'card_rejected_page_viewed',
  card_expired_page_viewed: 'card_expired_page_viewed',
  enable_manual_pay_action: 'enable_manual_pay_action',
  card_pending_transaction_help_clicked: 'card_pending_transaction_help_clicked',
  card_pending_transaction_help_cta_clicked: 'card_pending_transaction_help_cta_clicked',
  card_pending_payment_help_clicked: 'card_pending_payment_help_clicked',
  card_pot_detail_page_viewed: 'card_pot_detail_page_viewed',
  card_pot_detail_page_installments_filtered: 'card_pot_detail_page_installments_filtered',
  card_pot_detail_page_cta: 'card_pot_detail_page_cta',
  card_pot_pay_now_page_viewed: 'card_pot_pay_now_page_viewed',
  card_pot_pay_now_page_call_cta: 'card_pot_pay_now_page_call_cta',
  card_pot_delinquency_policy_acknowledged: 'card_pot_delinquency_policy_acknowledged',
  user_added_to_cards: 'user_added_to_cards',
  self_reported_income_expenses_screen_viewed: 'self_reported_income_expenses_screen_viewed',
  self_reported_income_expenses_submitted: 'self_reported_income_expenses_submitted',
  card_reschedule_payments_via_iam_page_viewed: 'card_reschedule_payments_via_iam_page_viewed',
  card_reschedule_payments_calendar_date_selected:
    'card_reschedule_payments_calendar_date_selected',
  card_reschedule_payments_screen_viewed: 'card_reschedule_payments_screen_viewed',
  card_reschedule_payments_screen_reschedule_cta_clicked:
    'card_reschedule_payments_screen_reschedule_cta_clicked',
  card_autopay_amount_selected: 'card_autopay_amount_selected',
  card_key_info_details_clicked: 'card_key_info_details_clicked',
  card_key_info_details_exited: 'card_key_info_details_exited',
  card_current_balance_help_clicked: 'card_current_balance_help_clicked',
  card_autopay_settings_page_viewed: 'card_autopay_settings_page_viewed',
} as const

export const LoanDashboardEvents = {
  charged_off_explanation_selected: 'charged_off_explanation_selected',
  reapply_for_loan_selected: 'reapply_for_loan_selected',
  default_explanation_selected: 'default_explanation_selected',
  contact_us_selected: 'contact_us_selected',
  rejected_explanation_selected: 'rejected_explanation_selected',
  state_db_explanation_selected: 'state_db_explanation_selected',
  update_payment_dates_selected: 'update_payment_dates_selected',
  pay_remaining_now_selected: 'pay_remaining_now_selected',
  relink_bank_account_selected: 'relink_bank_account_selected',
  view_history_selected: 'view_history_selected',
  update_payment_method_selected: 'update_payment_method_selected',
  // active dashboard alerts
  alert_viewed_in_default: 'alert_viewed_in_default',
  alert_viewed_charged_off: 'alert_viewed_charged_off',
  alert_viewed_one_missed_payment: 'alert_viewed_one_missed_payment',
  alert_viewed_payment_late_not_failed: 'alert_viewed_payment_late_not_failed',
  alert_viewed_suspended: 'alert_viewed_suspended',
  alert_viewed_payment_method_issue: 'alert_viewed_payment_method_issue',
  closed_dashboard_viewed: 'closed_dashboard_viewed', // includes arg { reason: '' }
  decisioning_dashboard_viewed: 'decisioning_dashboard_viewed', // args { delayed: boolean }
  active_dashboard_viewed: 'active_dashboard_viewed',
  approved_dashboard_viewed: 'approved_dashboard_viewed', // args { isCounterOffer: boolean }
  approved_accept_offer_selected: 'approved_accept_offer_selected',
  approved_view_counter_offer_selected: 'approved_view_counter_offer_selected',
  approved_decline_counter_offer_selected: 'approved_decline_counter_offer_selected',
  error_loan_dashboard_viewed: 'error_loan_dashboard_viewed',
  final_payment_processing_alert_tapped: 'final_payment_processing_alert_tapped',
} as const

export const ManageActiveLoanEvents = {
  // Apply
  apply_for_loan_selected: 'apply_for_loan_selected',
  // Reschedule payments
  reschedule_payments_selected: 'reschedule_payments_selected',
  // Pay Now
  pay_balance_now_screen_viewed: 'pay_balance_now_screen_viewed',
  pay_balance_now_selected: 'pay_balance_now_selected',
  pay_balance_now_modal_pay_selected: 'pay_balance_now_modal_pay_selected',
  pay_balance_now_modal_nevermind_selected: 'pay_balance_now_modal_nevermind_selected',
  pay_balance_now_confirmation_completed: 'pay_balance_now_confirmation_completed',
  // Payment reschedule calendar
  reschedule_payments_calendar_screen_viewed: 'reschedule_payments_calendar_screen_viewed',
  reschedule_payments_calendar_completed: 'reschedule_payments_calendar_completed',
  reschedule_payments_calendar_confirm_failed: 'reschedule_payments_calendar_confirm_failed',
  reschedule_payments_calendar_selected: 'reschedule_payments_calendar_selected',
  reschedule_alert_viewed: 'reschedule_alert_viewed',
  reschedule_alert_continue_selected: 'reschedule_alert_continue_selected',
  reschedule_alert_nevermind_selected: 'reschedule_alert_nevermind_selected',
  reschedule_payments_screen_viewed: 'reschedule_payments_screen_viewed',
  reschedule_payments_payment_selected: 'reschedule_payments_payment_selected',
  // Upgrade to installment
  // note: related events installment_upgrade_declined and screen_viewed are under the Checkout category instead of ManageActiveLoan
  installment_upgrade_accepted: 'installment_upgrade_accepted',
} as const

export const LoansAdhocPaymentEvents = {
  loans_adhoc_dashboard_make_a_payment_cta: 'loans_adhoc_dashboard_make_a_payment_cta',
  // Select Amount
  loans_adhoc_amount_entry_screen_viewed: 'loans_adhoc_amount_entry_screen_viewed',
  loans_adhoc_amount_entry_cta: 'loans_adhoc_amount_entry_cta',
  loans_adhoc_amount_entry_error_viewed: 'loans_adhoc_amount_entry_error_viewed',
  // Payment Review
  loans_adhoc_payment_review_screen_viewed: 'loans_adhoc_payment_review_screen_viewed',
  loans_adhoc_payment_review_edit_amount_selected:
    'loans_adhoc_payment_review_edit_amount_selected',
  loans_adhoc_payment_review_relink_account: 'loans_adhoc_payment_review_relink_account',
  loans_adhoc_payment_review_view_payment_methods:
    'loans_adhoc_payment_review_view_payment_methods',
  loans_adhoc_payment_review_cta: 'loans_adhoc_payment_review_cta',
  // Success
  loans_adhoc_success_screen_viewed: 'loans_adhoc_success_screen_viewed',
  // Failure
  loans_adhoc_failure_modal_viewed: 'loans_adhoc_failure_modal_viewed',
  loans_adhoc_failure_modal_cta_try_again: 'loans_adhoc_failure_modal_cta_try_again',
  loans_adhoc_failure_modal_cta_cancel: 'loans_adhoc_failure_modal_cta_cancel',
  // View Payment Methods
  loans_adhoc_payment_methods_screen_viewed: 'loans_adhoc_payment_methods_screen_viewed',
  loans_adhoc_payment_methods_change_payment_method:
    'loans_adhoc_payment_methods_change_payment_method',
  loans_adhoc_payment_methods_change_payment_method_completed:
    'loans_adhoc_payment_methods_change_payment_method_completed',
  loans_adhoc_payment_methods_error_viewed: 'loans_adhoc_payment_methods_error_viewed',
  loans_adhoc_payment_method_link_cta: 'loans_adhoc_payment_method_link_cta',
  // bank aggregator account selection
  loans_adhoc_aggregator_account_selection_screen_viewed:
    'loans_adhoc_aggregator_account_selection_screen_viewed',
  loans_adhoc_aggregator_account_selection_cta_selected:
    'loans_adhoc_aggregator_account_selection_cta_selected',
  loans_adhoc_aggregator_account_selection_verify_succeeded:
    'loans_adhoc_aggregator_account_selection_verify_succeeded',
  loans_adhoc_aggregator_account_selection_verify_failed:
    'loans_adhoc_aggregator_account_selection_verify_failed',
  // error screen viewed
  loans_adhoc_payment_error_screen_viewed: 'loans_adhoc_payment_error_screen_viewed',
} as const

export const AccountManagementEvents = {
  account_management_page_viewed: 'account_management_page_viewed',
  collect_bank_account_numbers: 'collect_bank_account_numbers',
  collect_bank_account_numbers_completed: 'collect_bank_account_numbers_completed',
  primary_account_info_lightbox_viewed: 'primary_account_info_lightbox_viewed',
  primary_account_info_page_viewed: 'primary_account_info_page_viewed',
  add_primary_account_page_viewed: 'add_primary_account_page_viewed',
  set_account_as_primary_page_viewed: 'set_account_as_primary_page_viewed',
  select_primary_account_page_viewed: 'select_primary_account_page_viewed',
  select_primary_account_page_select_cta: 'select_primary_account_page_select_cta',
  select_primary_account_page_cta: 'select_primary_account_page_cta',
  select_primary_account_page_error_viewed: 'select_primary_account_page_error_viewed',
  account_management_plaid_start: 'account_management_plaid_start',
  account_management_plaid_complete: 'account_management_plaid_complete',
  account_management_plaid_error: 'account_management_plaid_error',
  account_management_debit_card_start: 'account_management_debit_card_start',
  account_management_primary_account_set: 'account_management_primary_account_set',
  account_management_primary_account_set_error: 'account_management_primary_account_set_error',
  account_management_primary_account_set_update_loan_autopay_confirm:
    'account_management_primary_account_set_update_loan_autopay_confirm',
  account_management_primary_account_set_update_loan_autopay_deny:
    'account_management_primary_account_set_update_loan_autopay_deny',
  v1_account_management_plaid_start: 'v1_account_management_plaid_start',
  v1_account_management_plaid_complete: 'v1_account_management_plaid_complete',
  account_management_plaid_relink_error_message: 'account_management_plaid_relink_error_message',
  account_management_plaid_relink_clicked: 'account_management_plaid_relink_clicked',
  account_management_missing_routing_account_number_message:
    'account_management_missing_routing_account_number_message',
  change_default_payment_method_URA_viewed: 'change_default_payment_method_URA_viewed',
  change_default_payment_method_URA_completed: 'change_default_payment_method_URA_completed',
  select_personal_account_URA_viewed: 'select_personal_account_URA_viewd',
  select_personal_account_URA_completed: 'select_personal_account_URA_complete',
  select_personal_account_URA_error: 'select_personal_account_URA_error',
  select_personal_account_URA_CTA: 'select_personal_account_URA_CTA',
  link_account_with_new_name_URA_viewed: 'link_account_with_new_name_URA_viewed',
  link_account_with_new_name_URA_completed: 'link_account_with_new_name_URA_completed',
  link_account_with_new_name_URA_error: 'link_account_with_new_name_URA_error',
  link_account_with_new_name_URA_CTA: 'link_account_with_new_name_URA_CTA',
  relink_account_set_primary_URA_viewed: 'relink_account_set_primary_URA_viewed',
  relink_account_set_primary_URA_completed: 'relink_account_set_primary_URA_completed',
  relink_account_set_primary_URA_error: 'relink_account_set_primary_URA_error',
  relink_account_set_primary_URA_CTA: 'relink_account_set_primary_URA_CTA',
  bank_add_routing_and_account_page_viewed: 'bank_add_routing_and_account_page_viewed',
  bank_add_routing_and_account_error_viewed: 'bank_add_routing_and_account_error_viewed',
  bank_verify_routing_and_account_page_viewed: 'bank_verify_routing_and_account_page_viewed',
  bank_verify_routing_and_account_error_viewed: 'bank_verify_routing_and_account_error_viewed',
  bank_verify_routing_and_account_submit_completed: 'bank_verify_routing_and_account_page_viewed',
  bank_verify_routing_and_account_submit_failed: 'bank_verify_routing_and_account_submit_failed',
  // add new payment account
  add_new_payment_account: 'add_new_payment_account', // has { type: '' }
  add_new_payment_account_completed: 'add_new_payment_account_completed', // has { type: '' }
  add_new_payment_account_failed: 'add_new_payment_account_failed', // has { type: '' }
  // aggregator account selection for primary account
  primary_account_aggregator_account_selection_cta_selected:
    'primary_account_aggregator_account_selection_cta_selected',
  primary_account_aggregator_account_selection_verify_succeeded:
    'primary_account_aggregator_account_selection_verify_succeeded',
  primary_account_aggregator_account_selection_verify_failed:
    'primary_account_aggregator_account_selection_verify_failed',
} as const

export const WorkflowEvents = {
  workflow_navigation: 'workflow_navigation',
  workflow_navigation_failed: 'workflow_navigation_failed',
  signup_workflow_entered: 'signup_workflow_entered',
  signup_workflow_exited: 'signup_workflow_exited',
  signup_workflow_error: 'signup_workflow_error',
  offer_application_workflow_entered: 'offer_application_workflow_entered',
  offer_application_workflow_exited: 'offer_application_workflow_exited',
  offer_application_workflow_error: 'offer_application_workflow_error',
  application_activation_workflow_entered: 'application_activation_workflow_entered',
  application_activation_workflow_exited: 'application_activation_workflow_exited',
  application_activation_workflow_error: 'application_activation_workflow_error',
  application_activation_workflow_post_error_viewed:
    'application_activation_workflow_post_error_viewed',
  product_hub_screen_viewed: 'product_hub_screen_viewed',
  product_hub_workflow_error: 'product_hub_workflow_error',
  offer_application_workflow_error_contact_us_pressed:
    'offer_application_workflow_error_contact_us_pressed',
  application_activation_workflow_error_contact_us_pressed:
    'application_activation_workflow_error_contact_us_pressed',
  product_hub_workflow_error_contact_us_pressed: 'product_hub_workflow_error_contact_us_pressed',

  // these are temporary debug events
  // I doubt we will see these, but just incase...
  ensure_user_has_workflows_redirect_to_dashboard:
    'ensure_user_has_workflows_redirect_to_dashboard',
  workflow_pre_reqs_complete: 'workflow_pre_reqs_complete',
  workflow_unknown_destination: 'workflow_unknown_destination',
  workflow_setup_route_history_failed: 'workflow_setup_route_history_failed',
  workflow_initial_route_error: 'workflow_initial_route_error',
  product_hub_redirect: 'product_hub_redirect',
} as const

export const GeneralErrorEvents = {
  error_boundary_screen_viewed: 'error_boundary_screen_viewed',
  error_tile_viewed: 'error_tile_viewed',
} as const

export const PlaidSDKEvents = {
  plaid_refactor_activated: 'plaid_refactor_activated',
  plaid_bank_income_insights_completed: 'plaid_bank_income_insights_completed',
  plaid_close_oauth: 'plaid_close_oauth',
  plaid_error: 'plaid_error',
  plaid_exit: 'plaid_exit',
  plaid_fail_oauth: 'plaid_fail_oauth',
  plaid_handoff: 'plaid_handoff',
  plaid_identity_verification_start_step: 'plaid_identity_verification_start_step',
  plaid_identity_verification_pass_step: 'plaid_identity_verification_pass_step',
  plaid_identity_verification_fail_step: 'plaid_identity_verification_fail_step',
  plaid_identity_verification_pending_review_step:
    'plaid_identity_verification_pending_review_step',
  plaid_identity_verification_create_session: 'plaid_identity_verification_create_session',
  plaid_identity_verification_resume_session: 'plaid_identity_verification_resume_session',
  plaid_identity_verification_pass_session: 'plaid_identity_verification_pass_session',
  plaid_identity_verification_fail_session: 'plaid_identity_verification_fail_session',
  plaid_identity_verification_open_ui: 'plaid_identity_verification_open_ui',
  plaid_identity_verification_resume_ui: 'plaid_identity_verification_resume_ui',
  plaid_identity_verification_close_ui: 'plaid_identity_verification_close_ui',
  plaid_matched_consent: 'plaid_matched_consent',
  plaid_matched_select_institution: 'plaid_matched_select_institution',
  plaid_matched_select_verify_method: 'plaid_matched_select_verify_method',
  plaid_open: 'plaid_open',
  plaid_open_my_plaid: 'plaid_open_my_plaid',
  plaid_open_oauth: 'plaid_open_oauth',
  plaid_search_institution: 'plaid_search_institution',
  plaid_select_degraded_institution: 'plaid_select_degraded_institution',
  plaid_select_down_institution: 'plaid_select_down_institution',
  plaid_select_institution: 'plaid_select_institution',
  plaid_submit_credentials: 'plaid_submit_credentials',
  plaid_submit_mfa: 'plaid_submit_mfa',
  plaid_transition_view: 'plaid_transition_view',
  plaid_success: 'plaid_success',
  plaid_undefined_event: 'plaid_undefined_event',
  plaid_auto_select_saved_institution: 'plaid_auto_select_saved_institution',
  plaid_select_saved_institution: 'plaid_select_saved_institution',
  plaid_saved_institution: 'plaid_saved_institution',
  plaid_connect_new_institution: 'plaid_connect_new_institution',
  plaid_submit_phone: 'plaid_submit_phone',
  plaid_verify_phone: 'plaid_verify_phone',
  plaid_skip_submit_phone: 'plaid_skip_submit_phone',
} as const

const AppEvents = {
  Category: {
    Login: 'Login',
    InitialApplication: 'InitialApplication',
    Reapplication: 'Reapplication',
    Checkout: 'Checkout',
    ManageActiveLoan: 'ManageActiveLoan',
    LoanDashboard: 'LoanDashboard',
    Admin: 'Admin',
    ContactUs: 'ContactUs',
    AccountManagement: 'AccountManagement',
    Onboarding: 'Onboarding',
    Settings: 'Settings',
    Prequal: 'Pre-Qualification',
    RedirectToWebApp: 'RedirectToWebApp',
    Card: 'Card',
    Marketing: 'Marketing',
    Workflows: 'Workflows',
    PlaidSDK: 'PlaidSDK',
    LoansAdhocPayment: 'LoansAdhocPayment',
    GeneralError: 'GeneralError',
  },
  Name: {
    //Admin

    // url params
    url_params_found: 'url_params_found',

    //Login
    login_session_start: 'login_session_start',
    card_landing_screen_viewed: 'card_landing_screen_viewed',
    card_registration_page_viewed: 'card_registration_page_viewed',
    partner_landing_screen_viewed: 'partner_landing_screen_viewed',
    intro_tutorial_1_screen_viewed: 'intro_tutorial_1_screen_viewed',
    intro_tutorial_2_screen_viewed: 'intro_tutorial_2_screen_viewed',
    intro_tutorial_3_screen_viewed: 'intro_tutorial_3_screen_viewed',
    intro_tutorial_4_screen_viewed: 'intro_tutorial_4_screen_viewed',
    login_screen_viewed: 'login_screen_viewed',
    registration_screen_viewed: 'registration_screen_viewed',
    login_screen_method_selected: 'login_screen_method_selected',
    login_succeeded: 'login_succeeded',
    login_cancelled: 'login_cancelled',
    login_failed: 'login_failed',
    logout_succeeded: 'logout_succeeded',
    register_email_exists: 'register_email_exists',
    login_google_direct: 'login_google_direct',
    login_google_auth0_fallback: 'login_google_auth0_fallback',
    login_google_direct_error: 'login_google_direct_error',
    google_oauth_selected: 'google_oauth_selected',
    apple_signin_selected: 'apple_signin_selected',
    account_recovery_enter_phone_number_viewed: 'account_recovery_enter_phone_number_viewed',
    account_recovery_enter_phone_number_completed: 'account_recovery_enter_phone_number_completed',
    account_recovery_instructions_viewed: 'account_recovery_instructions_viewed',
    account_recovery_instructions_closed: 'account_recovery_instructions_closed',
    account_recovery_resend_selected: 'account_recovery_resend_selected',
    account_recovery_instructions_contact_us_selected:
      'account_recovery_instructions_contact_us_selected',
    login_other_clicked: 'login_other_clicked',
    accept_agreements_view_ecc_selected: 'accept_agreements_view_ecc_selected',
    edit_password_selected: 'edit_password_selected',
    edit_password_screen_viewed: 'edit_password_screen_viewed',
    edit_password_submitted: 'edit_password_submitted',
    edit_password_failed: 'edit_password_failed',
    create_password_selected: 'create_password_selected',
    edit_email_screen_viewed: 'edit_email_screen_viewed',
    edit_email_submitted: 'edit_email_submitted',
    edit_email_address_selected: 'edit_email_address_selected',
    profile_send_email_verification_code: 'profile_send_email_verification_code',
    otp_code_screen_viewed: 'otp_code_screen_viewed',
    otp_verification_code_submitted: 'otp_verification_code_submitted',
    otp_resend_verification_selected: 'otp_resend_verification_selected',
    binary_update_notification_shown: 'binary_update_notification_shown',

    //InitialApplication
    initial_state_selection_screen_viewed: 'initial_state_selection_screen_viewed',
    initial_state_selection_selected: 'initial_state_selection_selected',
    loan_amount_selection_screen_viewed: 'loan_amount_selection_screen_viewed',
    loan_amount_selection_loading_state_change: 'loan_amount_selection_loading_state_change',
    enter_promo_code_selected: 'enter_promo_code_selected',
    promo_code_screen_viewed: 'promo_code_screen_viewed',
    promo_code_entered: 'promo_code_entered',
    promo_code_helper_selected: 'promo_code_helper_selected',
    loan_amount_selected: 'loan_amount_selected',
    unfreeze_bureau_frozen_file_URA_viewed: 'unfreeze_bureau_frozen_file_URA_viewed',
    unfreeze_bureau_frozen_file_URA_completed: 'unfreeze_bureau_frozen_file_URA_completed',
    joint_account_verifcation_URA_viewed: 'joint_account_verifcation_URA_viewed',
    joint_account_verifcation_URA_completed: 'joint_account_verifcation_URA_completed',
    proof_of_income_URA_viewed: 'proof_of_income_URA_viewed',
    proof_of_income_URA_completed: 'proof_of_income_URA_completed',

    one_min_application_primer_screen_viewed: 'one_min_application_primer_screen_viewed',
    one_min_application_primer_completed: 'one_min_application_primer_completed',

    marketing_source_survey_screen_viewed: 'marketing_source_survey_screen_viewed',
    marketing_source_survey_selected: 'marketing_source_survey_selected',

    front_id_photo_screen_viewed: 'front_id_photo_screen_viewed',
    front_id_photo_taken: 'front_id_photo_taken',
    front_id_portrait_photo_taken: 'front_id_portrait_photo_taken',
    front_id_photo_orientation_changed: 'front_id_photo_orientation_changed',
    front_id_photo_confirmation_screen_viewed: 'front_id_photo_confirmation_screen_viewed',
    front_id_photo_retaken: 'front_id_photo_retaken',
    front_id_photo_submitted: 'front_id_photo_submitted',

    back_id_scan_screen_viewed: 'back_id_scan_screen_viewed',
    back_id_scan_scanned: 'back_id_scan_scanned',
    back_id_scan_manual_enter_selected: 'back_id_scan_manual_enter_selected',

    personal_details_screen_viewed: 'personal_details_screen_viewed',
    personal_details_address_screen_viewed: 'personal_details_address_screen_viewed',
    personal_details_address_screen_completed: 'personal_details_address_screen_completed',
    personal_details_ssn_helper_selected: 'personal_details_ssn_helper_selected',
    personal_details_ssn_screen_viewed: 'personal_details_ssn_screen_viewed',
    personal_details_ssn_completed: 'personal_details_ssn_completed',
    personal_details_completed: 'personal_details_completed',
    personal_details_confirm_page_viewed: 'personal_details_confirm_page_viewed',
    personal_details_confirm_page_completed: 'personal_details_confirm_page_completed',

    loan_product_intro_page_viewed: 'loan_product_intro_page_viewed',

    take_a_selfie_screen_viewed: 'take_a_selfie_screen_viewed',
    take_a_selfie_completed: 'take_a_selfie_completed',

    link_bank_account_primer_viewed: 'link_bank_account_primer_viewed',
    link_bank_account_primer_completed: 'link_bank_account_primer_completed',
    link_bank_account_bank_selected: 'link_bank_account_bank_selected',
    link_bank_account_token_undefined: 'link_bank_account_token_undefined',

    link_bank_account_successful: 'link_bank_account_successful',
    link_bank_account_failed: 'link_bank_account_failed',
    link_bank_account_cancelled: 'link_bank_account_cancelled',

    link_bank_account_unsupported_institution_viewed:
      'link_bank_account_unsupported_institution_viewed',
    link_bank_account_unsupported_institution_try_again:
      'link_bank_account_unsupported_institution_try_again',

    unsupported_institutions_screen_viewed: 'unsupported_institutions_screen_viewed',

    link_bank_account_unsupported_account_viewed: 'link_bank_account_unsupported_account_viewed',
    link_bank_account_unsupported_account_try_again:
      'link_bank_account_unsupported_account_try_again',

    link_bank_account_switch_provider: 'link_bank_account_switch_provider',

    completed_application_submission_screen_viewed:
      'completed_application_submission_screen_viewed',

    application_status_helper_selected: 'application_status_helper_selected',
    faq_selected: 'faq_selected',

    loan_application_processing_screen_viewed: 'loan_application_processing_screen_viewed',
    loan_approved_screen_viewed: 'loan_approved_screen_viewed',
    loan_not_approved_screen_viewed: 'loan_not_approved_screen_viewed',
    loan_approved_completed: 'loan_approved_completed',

    loan_counter_offer_accepted: 'loan_counter_offer_accepted',
    loan_counter_offer_completed: 'loan_counter_offer_completed',
    loan_counter_offer_declined: 'loan_counter_offer_declined',
    loan_counter_offer_screen_viewed: 'loan_counter_offer_screen_viewed',
    loan_counter_offer_rejected: 'loan_counter_offer_rejected',

    loan_disbursement_method_selection_screen_viewed:
      'loan_disbursement_method_selection_screen_viewed',
    disbursement_method_selected: 'disbursement_method_selected',
    disbursement_method_confirmed: 'disbursement_method_confirmed',
    disbursement_method_debit_link_success: 'disbursement_method_debit_link_success',
    disbursement_method_debit_link_fail: 'disbursement_method_debit_link_fail',
    confirm_debit_card_screen_viewed: 'confirm_debit_card_screen_viewed',
    suggested_debit_card_confirmed: 'suggested_debit_card_confirmed',
    set_suggested_debit_card_fail: 'set_suggested_debit_card_fail',
    set_suggested_debit_card_success: 'set_suggested_debit_card_success',

    enter_phone_number_screen_viewed: 'enter_phone_number_screen_viewed',
    enter_phone_number_completed: 'enter_phone_number_completed',

    enter_verification_code_screen_viewed: 'enter_verification_code_screen_viewed',
    enter_verification_code_resend_selected: 'enter_verification_code_resend_selected',
    enter_verification_code_phone_call_selected: 'enter_verification_code_phone_call_selected',
    enter_verification_code_contact_us_selected: 'enter_verification_code_contact_us_selected',
    enter_verification_code_submitted: 'enter_verification_code_submitted',

    send_email_verification_code_screen_viewed: 'send_email_verification_code_screen_viewed',
    send_email_verification_code_selected: 'send_email_verification_code_selected',
    enter_email_verification_code_screen_viewed: 'enter_email_verification_code_screen_viewed',
    enter_email_verification_code_resend_selected: 'enter_verification_code_resend_selected',
    enter_email_verification_code_submitted: 'enter_email_verification_code_submitted',

    review_payment_schedule_screen_viewed: 'review_payment_schedule_screen_viewed',
    review_payment_schedule_screen_helper_selected:
      'review_payment_schedule_screen_helper_selected',
    review_payment_schedule_screen_completed: 'review_payment_schedule_screen_completed',

    tila_disclosure_screen_viewed: 'tila_disclosure_screen_viewed',
    tila_disclosure_screen_completed: 'tila_disclosure_screen_completed',

    how_payments_work_screen_viewed: 'how_payments_work_screen_viewed',
    how_payments_work_completed: 'how_payments_work_completed',

    accept_agreements_screen_viewed: 'accept_agreements_screen_viewed',
    accept_agreements_accept_ach_selected: 'accept_agreements_accept_ach_selected',
    accept_agreements_not_accept_ach_selected: 'accept_agreements_not_accept_ach_selected',

    non_ach_option_screen_viewed: 'non_ach_option_screen_viewed',
    non_ach_option_agree_to_delay_of_funds_selected:
      'non_ach_option_agree_to_delay_of_funds_selected',
    non_ach_option_nevermind_selected: 'non_ach_option_nevermind_selected',

    accept_agreements_accept_loan_agreement_selected:
      'accept_agreements_accept_loan_agreement_selected',
    accept_agreements_view_ach_agreement_selected: 'accept_agreements_view_ach_agreement_selected',
    accept_agreements_view_loan_agreement_selected:
      'accept_agreements_view_loan_agreement_selected',
    accept_agreements_view_tos_selected: 'accept_agreements_view_tos_selected',
    accept_agreements_view_state_licenses_selected:
      'accept_agreements_view_state_licenses_selected',
    accept_agreements_view_privacy_policy_selected:
      'accept_agreements_view_privacy_policy_selected',
    accept_agreements_completed: 'accept_agreements_completed',
    conditions_for_approval_disclosure_selected: 'conditions_for_approval_disclosure_selected',

    relink_preferred_account_screen_viewed: 'relink_preferred_account_screen_viewed',
    relink_preferred_account_screen_completed: 'relink_preferred_account_screen_completed',

    // Upgrade to installment
    installment_upgrade_screen_viewed: 'installment_upgrade_screen_viewed',
    installment_upgrade_declined: 'installment_upgrade_declined',
    installment_upgrade_declined_modal_back: 'installment_upgrade_declined_modal_back',
    installment_upgrade_declined_confirmed: 'installment_upgrade_declined_confirmed',

    confirm_bank_account_viewed: 'confirm_bank_account_viewed',
    confirm_bank_account_completed: 'confirm_bank_account_completed',

    purpose_for_loan_screen_viewed: 'purpose_for_loan_screen_viewed',
    purpose_for_loan: 'purpose_for_loan',

    final_loan_acceptance_screen_viewed: 'final_loan_acceptance_screen_viewed',
    final_loan_acceptance_completed: 'final_loan_acceptance_completed',
    final_loan_acceptance_failed: 'final_loan_acceptance_failed',
    completed_application_submitted: 'completed_application_submitted',

    your_money_is_on_the_way_screen_viewed: 'your_money_is_on_the_way_screen_viewed',
    your_money_is_on_the_way_completed: 'your_money_is_on_the_way_completed',

    customer_satisfaction_survey_screen_viewed: 'customer_satisfaction_survey_screen_viewed',
    customer_satisfaction_survey_yes_selected: 'customer_satisfaction_survey_yes_selected',
    customer_satisfaction_survey_no_selected: 'customer_satisfaction_survey_no_selected',
    customer_satisfaction_survey_leave_a_review_selected:
      'customer_satisfaction_survey_leave_a_review_selected',
    customer_satisfaction_survey_maybe_later_selected:
      'customer_satisfaction_survey_maybe_later_selected',
    customer_satisfaction_survey_other_feedback_selected:
      'customer_satisfaction_survey_other_feedback_selected',

    contact_us_screen_viewed: 'contact_us_screen_viewed',
    contact_us_submitted: 'contact_us_submitted',
    contact_us_submission_confirmation_screen_viewed:
      'contact_us_submission_confirmation_screen_viewed',
    contact_us_submission_confirmation_completed: 'contact_us_submission_confirmation_completed',
    contact_us_category_selected: 'contact_us_category_selected',
    contact_us_category_action_taken: 'contact_us_category_action_taken',

    reschedule_payments_completed: 'reschedule_payments_completed',

    loan_agreement_selected: 'loan_agreement_selected',
    single_payment_loan_agreement_selected: 'single_payment_loan_agreement_selected',
    installment_loan_agreement_selected: 'installment_loan_agreement_selected',
    ach_agreement_selected: 'ach_agreement_selected',
    credit_service_agreement_selected: 'credit_service_agreement_selected',
    credit_service_disclosure_selected: 'credit_service_disclosure_selected',
    arbitration_agreement_selected: 'arbitration_agreement_selected',
    pay_off_confirmation_doc_selected: 'pay_off_confirmation_doc_selected',

    lefthand_drawer_profile_selected: 'lefthand_drawer_profile_selected',
    lefthand_drawer_profile_screen_viewed: 'lefthand_drawer_profile_screen_viewed',
    lefthand_drawer_loan_history_selected: 'lefthand_drawer_loan_history_selected',
    lefthand_drawer_loan_history_screen_viewed: 'lefthand_drawer_loan_history_screen_viewed',
    lefthand_drawer_contact_us_selected: 'lefthand_drawer_contact_us_selected',
    lefthand_drawer_about_selected: 'lefthand_drawer_about_selected',
    lefthand_drawer_faq_selected: 'lefthand_drawer_faq_selected',
    lefthand_drawer_legal_selected: 'lefthand_drawer_legal_selected',
    lefthand_drawer_legal_screen_viewed: 'lefthand_drawer_legal_screen_viewed',
    lefthand_drawer_legal_pf_tos_selected: 'lefthand_drawer_legal_pf_tos_selected',
    lefthand_drawer_legal_pf_privacy_policy_selected:
      'lefthand_drawer_legal_pf_privacy_policy_selected',
    lefthand_drawer_legal_pf_state_licenses_selected:
      'lefthand_drawer_legal_pf_state_licenses_selected',
    lefthand_drawer_legal_synapsefi_tos_selected: 'lefthand_drawer_legal_synapsefi_tos_selected',
    lefthand_drawer_legal_synapsefi_privacy_policy_selected:
      'lefthand_drawer_legal_synapsefi_privacy_policy_selected',
    lefthand_drawer_legal_dwolla_tos_selected: 'lefthand_drawer_legal_dwolla_tos_selected',
    lefthand_drawer_legal_dwolla_privacy_policy_selected:
      'lefthand_drawer_legal_dwolla_privacy_policy_selected',
    lefthand_drawer_logout_selected: 'lefthand_drawer_logout_selected',
    lefthand_drawer_card_statements_and_documents_selected:
      'lefthand_drawer_card_statements_and_documents_selected',
    lefthand_drawer_card_statements_and_documents_screen_viewed:
      'lefthand_drawer_card_statements_and_documents_screen_viewed',
    card_statement_selected: 'card_statement_selected',
    cardholder_agreement_selected: 'cardholder_agreement_selected',
    cardholder_beta_agreement_selected: 'cardholder_beta_agreement_selected',
    cardholder_repayment_authorization_selected: 'cardholder_repayment_authorization_selected',

    link_new_institution: 'link_new_institution',
    link_new_institution_success: 'link_new_institution_success',
    link_new_institution_failed: 'link_new_institution_failed',

    edit_payment_account: 'edit_payment_account',
    edit_payment_account_good_selected: 'edit_payment_account_good_selected',
    edit_payment_account_bad_selected: 'edit_payment_account_bad_selected',
    edit_payment_account_add_new_selected: 'edit_payment_account_add_new_selected',
    edit_payment_account_ineligible: 'edit_payment_account_ineligible',
    change_payment_method_error: 'change_payment_method_error',

    remove_bank_selected: 'remove_bank_selected',
    remove_bank_accepted: 'remove_bank_accepted',
    remove_bank_declined: 'remove_bank_declined',

    relink_bank_selected: 'relink_bank_selected',
    relink_bank_accepted: 'relink_bank_accepted',
    relink_bank_cancelled: 'relink_bank_cancelled',

    switch_account_agreement_accepted: 'switch_account_agreement_accepted',

    provide_proof_of_residency_completed: 'provide_proof_of_residency_completed',
    provide_ssn_card_completed: 'provide_ssn_card_completed',
    ssn_photo_screen_viewed: 'ssn_photo_screen_viewed',
    ssn_photo_submitted: 'ssn_photo_submitted',
    ssn_photo_retaken: 'ssn_photo_retaken',
    ssn_photo_confirmation_screen_viewed: 'ssn_photo_confirmation_screen_viewed',
    ssn_photo_taken: 'ssn_photo_taken',

    //AccountManagement
    plaid_event: 'plaid_event',

    //Notification Permissions
    notification_value_prop_accepted: 'notification_value_prop_accepted',
    notification_value_prop_declined: 'notification_value_prop_declined',

    //Onboarding
    personalizing_screen_viewed: 'personalizing_screen_viewed',
    click_start_by_linking: 'click_start_by_linking',

    // Debit card flow
    add_payment_method_account_selected: 'add_payment_method_account_selected',
    add_payment_method_debit_selected: 'add_payment_method_debit_selected',
    add_debit_card_account_skip: 'add_debit_card_account_skip',
    add_debit_card_confirmed: 'add_debit_card_confirmed',
    add_debit_card_account_selected: 'add_debit_card_account_selected',
    add_debit_card_account_add_bank: 'add_debit_card_account_add_bank',

    //covered borrower agreement
    covered_borrower_agreement_option_selected: 'covered_borrower_agreement_option_selected',

    //state disclosure
    state_disclosure_screen_viewed: 'state_disclosure_screen_viewed',
    state_disclosure_accepted: 'state_disclosure_accepted',
    state_disclosure_exit: 'state_disclosure_exit',

    // WA installment loan
    wa_installment_accept_terms: 'wa_installment_accept_terms',
    wa_installment_upgrade_declined_modal_back: 'wa_installment_upgrade_declined_modal_back',
    wa_installment_upgrade_declined_confirmed: 'wa_installment_upgrade_declined_confirmed',
    replacement_loan_agreement_selected: 'replacement_loan_agreement_selected',
    replacement_loan_ach_agreement_selected: 'replacement_loan_ach_agreement_selected',

    // App to Web Redirection
    redirect_to_webapp_begin: 'redirect_to_webapp_begin',
    redirect_to_webapp_complete: 'redirect_to_webapp_complete',

    // Verify Bank Details
    verify_bank_details_page_viewed: 'verify_bank_details_page_viewed',
    verify_bank_details_submit_cta: 'verify_bank_details_submit_cta',
    verify_bank_details_payment_account_select: 'verify_bank_details_payment_account_select',
    verify_bank_details_accounted_added_modal_confirm_cta:
      'verify_bank_details_accounted_added_modal_confirm_cta',

    // Prequal
    ...PrequalEvents,

    ...CardEvents,

    ...DeeplinkEvents,

    ...WorkflowEvents,

    ...LoanDashboardEvents,

    ...ManageActiveLoanEvents,

    ...LoansAdhocPaymentEvents,

    ...AccountManagementEvents,

    ...PlaidSDKEvents,

    ...GeneralErrorEvents,
  },
} as const //const assertion to get the literal types from the object

export default AppEvents

type ValueOf<T> = T[keyof T]
export type AppEventName = ValueOf<typeof AppEvents.Name>
export type AppEventCategory = ValueOf<typeof AppEvents.Category>

export type AppEvent = {
  name: AppEventName
  category: AppEventCategory
  eventArgs?: {[key: string]: unknown}
}
